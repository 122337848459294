import Vue from 'vue'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/style.css';

//引用富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
 //引入富文本css
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import App from './App.vue'
import router from './router'
import {
	asyncRoutes
} from './router/routers'
// import axios from 'axios'

import axios from '../node_modules/axios'
import qs from 'qs'
import resultCode from '@/utils/code'

//富文本编辑器添加实例
Vue.use(VueQuillEditor)
Vue.use(ElementUI,{size:'small'});
Vue.config.productionTip = false
Vue.prototype.resultCode = resultCode
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;

axios.defaults.withCredentials = false

// 登录页路由拦截
router.beforeEach((to, from, next) => {
	console.log(to);
	console.log(from);
	if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
		if (localStorage.getItem('token')) { //判断本地是否存在access_token
			next();
		} else {
			next({
				path: 'login',
				query: {

					redirect: to.fullPath

				}
			})

		}
	} else {
		next();
	}
	/*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
	if (to.fullPath == "/login") {
		if (localStorage.getItem('access_token')) {
			next({
				path: from.fullPath
			});
		} else {
			next();
		}
	}
});

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
