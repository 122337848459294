<template>
	<div class="layout">
		<el-container>
			<!-- 头部 -->
			<el-header height="65px;">
        <div style="width: 100%; height: 65px; line-height: 65px; background: #fff;border-bottom: 1px solid #e6e6e6;">
					<img :src="logo" class="top_logo" alt="" srcset="" style="margin-left: 20px;" />
					<div class="container_login">
						<img src="../assets/approve.png" alt="" srcset="" />
						<el-dropdown>
							<span class="el-dropdown-link" style="color: #333;">
								{{shop_name}}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item @click.native="clearadd">退出</el-dropdown-item>
								<!-- <el-dropdown-item>分享链接</el-dropdown-item> -->
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</el-header>
			<el-container>
				<!-- 左边菜单栏 -->
				<el-aside width="16em"
					style="height: calc(100vh - 70px);background: #fff;border-right: solid 1px #e6e6e6;">
					<el-menu>
						<el-submenu index="0">
							<template slot="title"><i class="el-icon-s-goods"></i>首页</template>
							<el-menu-item index="/" @click="goTo('/')">数据看板</el-menu-item>
						</el-submenu>
						<el-submenu index="1">
							<template slot="title"><i class="el-icon-s-goods"></i>商品管理</template>
							<el-menu-item index="/Productlist" @click="goTo('/Productlist')">商品列表</el-menu-item>
							<el-menu-item index="/classRun" @click="goTo('/classRun')">品类管理</el-menu-item>
							<!-- <el-menu-item index="/goodsaudit" @click="goTo('/goodsaudit')">审核商品</el-menu-item> -->
						</el-submenu>
						<el-submenu index="2">
							<template slot="title"><i class="el-icon-s-order"></i>订单管理</template>
							<el-menu-item index="/regularOrderG" @click="goTo('/regularOrderG')">常规订单</el-menu-item>
							<el-menu-item index="/closingOrderG" @click="goTo('/closingOrderG')">成交订单</el-menu-item>
							<el-menu-item index="/overdueOrderG" @click="goTo('/overdueOrderG')">逾期订单</el-menu-item>
							<el-menu-item index="/closeOrderG" @click="goTo('/closeOrderG')">结清订单</el-menu-item>
							<!-- <el-submenu index="2-1">
								<template slot="title">平台订单</template>
								<el-menu-item index="2-1-1" @click="goTo('/regularOrder')">常规订单</el-menu-item>
								<el-menu-item index="2-1-2" @click="goTo('/closingOrder')">成交订单</el-menu-item>
								<el-menu-item index="2-1-3" @click="goTo('/overdueOrder')">逾期订单</el-menu-item>
								<el-menu-item index="2-1-4" @click="goTo('/closeOrder')">结清订单</el-menu-item>
							</el-submenu> -->
							<!-- <el-submenu index="2-2">
								<template slot="title">供应商订单</template>
							</el-submenu> -->
						</el-submenu>
						<el-submenu index="3">
							<template slot="title"><i class="el-icon-s-home"></i>店铺管理</template>
							<el-menu-item index="3-1" @click="goTo('/returnAddress')">归还地址</el-menu-item>
							<el-menu-item index="3-5" @click="goTo('/return')">归还审核</el-menu-item>
							<el-menu-item index="3-2" @click="goTo('/rentAddress')">租赁地址</el-menu-item>
							<el-menu-item index="3-3" @click="goTo('/stores')">店铺信息</el-menu-item>
							<el-menu-item index="3-4" @click="goTo('/business')">旺铺管理</el-menu-item>
						</el-submenu>
						<el-submenu index="4">
							<template slot="title"><i class="el-icon-user-solid"></i>账号管理</template>
							<el-menu-item index="/usersList" @click="goTo('/usersList')">账号列表</el-menu-item>
						</el-submenu>
						<el-submenu index="5">
							<template slot="title"><i class="el-icon-s-help"></i>权限管理</template>
							<el-menu-item index="/roleList" @click="goTo('/roleList')">权限列表</el-menu-item>
						</el-submenu>
						<el-submenu index="6">
							<template slot="title"><i class="el-icon-s-finance"></i>账户管理</template>

							<el-menu-item index="/recharge" @click="goTo('/recharge')">风控充值</el-menu-item>
							<el-menu-item index="/orderFinancialList" @click="goTo('/orderFinancialList')">订单财务列表</el-menu-item>
							<el-menu-item index="/billFinancialList" @click="goTo('/billFinancialList')">账单财务列表</el-menu-item>
							<el-menu-item index="/myAccount" @click="goTo('/myAccount')">我的账户</el-menu-item>
							<!-- <el-menu-item index="/bill" @click="goTo('/bill')">对账流水</el-menu-item> -->
							<el-menu-item index="/record" @click="goTo('/record')">提现记录</el-menu-item>
							<!-- <el-menu-item index="/repayment" @click="goTo('/repayment')">还款记录</el-menu-item> -->
						</el-submenu>
						<!-- <el-submenu index="7">
							<template slot="title"><i class="el-icon-s-data"></i>退款管理</template>
							<el-menu-item index="/usersList" @click="goTo('/refund')">仅退款列表</el-menu-item>
						</el-submenu> -->
					</el-menu>
				</el-aside>

				<!-- main -->
				<el-main>
					<!-- <div class="bread_top">
						<el-breadcrumb>
							<el-breadcrumb-item v-for="item in breadList" :key="item.path" :to="item.path">
								{{ item.name }}</el-breadcrumb-item>
						</el-breadcrumb>
					</div> -->
					<router-view></router-view>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<script>
import config from '@/config/platform'
const { PLATFORM } = config
	export default {
		name: 'layout',
		data() {
			return {
				shop_name:'',
        logo:''
			};
		},


		created() {
      this.logo = require(`../../src/assets/logo_${PLATFORM}.png`)


      this.shop_name = localStorage.getItem("shop_name")

			// this.getBreadcrumb();
		},
		watch: {

			// $route() {
			// 	this.getBreadcrumb()
			// }
		},
		methods: {

			clearadd() {
			      localStorage.clear()
			   this.$router.replace('/login')
			    },

			goTo(path) {
				console.log(path)
				this.$router.replace(path);

			},
			// getBreadcrumb() {
			// 	// this.breadList = this.$route.matched.filter(item => item !== this.$route.matched[0]);
			// 	// this.breadList.splice(0, 1)
			// 	// console.log(this.breadList)

			// 	let route = this.$route.matched[1]
			// 	let bred = this.breadList;
			// 	bred.push({
			// 		path: route.path,
			// 		name: route.name
			// 	})
			// 	console.log(bred)
			// 	this.breadList = bred.slice(0, bred.findIndex(res => res.name === route.name) + 1)
			// 	// this.breadList
			// 	// console.log(this.$route.matched)
			// 	console.log(this.breadList)

			// },


			/**
			 * @param {Object} userFileParentId
			 */





			// cardFolderPrevious: function (userFileParentId) {
			//       this.fileCard.cardBreadcrumbs = this.fileCard.cardBreadcrumbs.slice(0, this.fileCard.cardBreadcrumbs
			//         .findIndex(res => res.userFileId === userFileParentId) + 1)
			//     },
			// cardFolderNext: function (item) {
			//       // 增加面包屑导航数据
			//       this.fileCard.cardBreadcrumbs.push({
			//         userFileId: item.userFileId,
			//         userFileName: item.userFileName
			//       })
			//     },

		}

	};
</script>
<style scoped>
	.el-main{
		padding: 0 20px;
		height: calc(100vh - 80px);
	}
	.el-dropdown-link {
		cursor: pointer;
		color: #409EFF;
	}

	.el-icon-arrow-down {
		font-size: 12px;
	}

	.demonstration {
		display: block;
		color: #8492a6;
		font-size: 14px;
		margin-bottom: 20px;
	}

	.container_login {
		float: right;
		text-align: left;
		color: #fff;
		position: relative;
		left: -20px;
	}

	.container_login img {
		width: 40px;
		height: 40px;
		position: relative;
		top: 15px;
		left: -10px;
	}

	.top_logo {
		height: 50px;
		position: relative;
		top: 5px;
		/* left: 80px; */
	}

	.el-header {
		padding: 0;
	}

	.bread_top {
		width: 100%;
		height: 40px;
		background: #fff;
		position: relative;
		top: -15px;
	}

	.el-breadcrumb {
		line-height: 40px;
	}

	.el-menu {
		border-right: solid 0px #e6e6e6;
	}

	.el-col-12 {
		width: 15%;
	}

	.el-menu-item:focus,
	.el-menu-item:hover {
		outline: 0;
		color: #fff;
		background-color: #2B80FF;
	}

	.el-submenu__title:hover {
		color: #fff;
		background-color: #2B80FF;
	}
</style>
